window.addEventListener("DOMContentLoaded", function () {
  Spree.setChFlowCookie();

  document.addEventListener("turbolinks:load", function () {
    console.log("turbolinks:load");
    const params = new URLSearchParams(document.location.search);
    const welcome_popup = params.get("welcome_pop");
    if (welcome_popup) {
      $('#popup-modal').modal('show');
    }
  });
});
